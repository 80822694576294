import React, {useEffect} from 'react';
import PageTitle from '../../../common/layouts/LayoutPrivate/PageTitle/PageTitle';

const Estimativas = () => {
    useEffect(() => {
        document.body.className = "page-estimativas";

        return () => {
            document.body.className = "";
        }
    }, []);

    return (
        <h2>
            Estimativas
        </h2>
    )
}

export default Estimativas;
