import React from "react";
import './Header.scss';
import PageTitle from '../PageTitle/PageTitle';
import Notifications from '../../../components/Notifications/Notifications';
import LocalPicker from '../../../components/LocalPicker/LocalPicker';

const Header = () => {
    return (
        <header className={"page-header"}>
            <div className={"panel header"}>
                <PageTitle />

                <div className={"header-actions"}>
                    <Notifications />
                    <LocalPicker />
                </div>
            </div>
        </header>
    );
}

export default Header;
