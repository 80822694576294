import React, {useState} from "react";
import logo from '../../../../assets/media/logo/logo.png';
import {Container} from "@mui/material";
import './Header.scss';
import {NavLink} from 'react-router-dom';
import Navigation from '../../../components/Navigation/Navigation';
import ButtonAuth from '../../../utilities/ButtonAuth';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        const html = document.querySelector('html');
        const icon = document.getElementById("toggle-menu");
        const icon1 = document.getElementById("a");
        const icon2 = document.getElementById("b");
        const icon3 = document.getElementById("c");

        if (icon && icon1 && icon2 && icon3) {
            icon1.classList.toggle('a');
            icon2.classList.toggle('c');
            icon3.classList.toggle('b');
        }

        html && html.classList.toggle("menu-open")
        setMenuOpen(!menuOpen);
    }

    return (
        <header className={'page-header'}>
            <Container className={'container header-container'}>
                <button className={'menu-toggle'} id={"toggle-menu"} onClick={toggleMenu}>
                    <div className="icon-1" id="a"></div>
                    <div className="icon-2" id="b"></div>
                    <div className="icon-3" id="c"></div>
                    <div className="clear"></div>
                </button>
                <div className={'logo-wrapper'}>
                    <NavLink to={'/'} id={'logo'}>
                        <img src={logo} alt={'Mobilidade Escolar'} width={236} height={51}/>
                    </NavLink>
                </div>
                <div className={"navigation-section"}>
                    <Navigation/>

                    <div className={'auth-section'}>
                        <ButtonAuth />
                    </div>
                </div>
            </Container>
        </header>
    );
}

export default Header;
