import React from 'react';
import {BannerInterface} from './BannerInterface';
import {Typography} from '@mui/material';
import {ButtonLink} from '../../utilities/ButtonLink';
import './Banner.scss'
const Banner: React.FC<BannerInterface> = ({title, desc, img, btn}: BannerInterface) => {
    return (
        <div className={"banner"}>
            <div className={"img-wrapper"}>
                <picture>
                    <source media={"(max-width: 767px)"} srcSet={img.srcMobile}/>
                    <img src={img.srcDesktop} alt={img.alt}/>
                </picture>
            </div>
            <div className={"desc-overlay"}>
                {title && <Typography className={"title"}>{title}</Typography>}
                {desc && <Typography className={"desc"}>{desc}</Typography>}
                {(btn && (btn.type === 'primary')) && <ButtonLink className={"primary"} variant={"contained"} link={btn.link} text={btn.text}/>}
                {(btn && (btn.type === 'secondary')) && <ButtonLink className={"secondary"} variant={"outlined"} link={btn.link} text={btn.text}/>}
            </div>
        </div>
    );
}

export default Banner;
