export type NavLinkProps = {
    text?: string;
    link: string;
    icon?: string;
    className?: string;
    children?:any;
};

export type  MenuItemProps = NavLinkProps & {
    submenu?: MenuItemProps[];
}

export type MenuProps = MenuItemProps[];

export const menu: MenuProps =
    [
        {
            "text": "Início",
            "link": "/"
        },
        {
            "text": "Mobilidade Escolar",
            "link": "/mobilidade-escolar"
        },
        {
            "text": "Sobre o projeto",
            "link": "/sobre"
        },
        {
            "text": "Quem Somos",
            "link": "/quem-somos"
        },
        {
            "text": "Contactos",
            "link": "/contactos"
        }
    ];
