import React, {Suspense} from 'react';
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme/_MUI/theme";
import './App.scss';
import 'swiper/css';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import ScrollToTop from './common/utilities/ScrollToTop';
import {Routes, Route} from 'react-router-dom'
import LayoutPublic from './common/layouts/LayoutPublic/LayoutPublic';
import publicRoutes from './routes/publicRoutes';
import privateRoutes from './routes/privateRoutes';
import Home from './areas/public/home/Home';
import {BrowserRouter} from 'react-router-dom';
import PrivateRoute from './helpers/PrivateRoute';

function App() {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop/>
                <ThemeProvider theme={theme}>
                    <Suspense fallback={"Loading..."}>
                        <Routes>
                            <Route path={"/"} element={<LayoutPublic/>}>
                                {
                                    publicRoutes.map(({element, path}, key) => (
                                        <Route element={element} path={path} key={key}/>
                                    ))
                                }
                            </Route>
                            <Route path={"/dashboard"} element={<PrivateRoute/>}>
                                {
                                    privateRoutes.map(({element, path}, key) => (
                                        <Route element={element} path={path} key={key}/>
                                    ))
                                }
                            </Route>
                            <Route path={"*"} element={<LayoutPublic/>}>
                                <Route path={"*"} element={<Home/>}/>
                            </Route>
                        </Routes>
                    </Suspense>
                </ThemeProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
