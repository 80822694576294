import React from "react";
import './Footer.scss';
import Copyright from '../../../components/Copyright/Copyright';

const Footer = () => {
    return (
        <footer className={"page-footer"}>
            <Copyright />
        </footer>
    );
}

export default Footer;
