import React, {useEffect} from 'react';
import './Alert.scss'
import {Typography} from '@mui/material';

interface InterfaceAlert {
    background: string,
    timer: number,
    message: string
}

const Alert: React.FC<InterfaceAlert> = ({background, timer, message}: InterfaceAlert) => {
    const hideAlert = () => {
        document.getElementById("dnxt-alert")!.classList.remove('show');
    }

    const showAlert = () => {
        document.getElementById("dnxt-alert")!.classList.add('show');
    }

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        showAlert();

        setTimeout(() => {
            hideAlert();
        }, timer);
    }, [timer])

    return (
        <div id={"dnxt-alert"}
             className={"alert"}
             style={{backgroundColor: background}}>
            <div className={"inner"}>
                <Typography className={"message"}>
                    {message}
                </Typography>
                <span className={"hide-alert"} onClick={hideAlert}></span>
            </div>
        </div>
    );
}

export default Alert;
