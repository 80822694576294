import React from 'react';
import Jumbotron from '../../../common/components/Jumbotron/Jumbotron';
import tisBanner from '../../../assets/media/public/quem-somos/tisBanner.png';
import dnxtBanner from '../../../assets/media/public/quem-somos/dnxtBanner.jpg';

const QuemSomos = () => {
    return (
        <div className={"cms-page quem-somos"}>
            <section id={"section1"}>
                <Jumbotron
                    subtitle={{tag: "h1", text: "Como surgiu este projeto"}}
                    desc={"Inicialmente este projeto foi desenvolvido em parceria pela TIS e pela DNXT aliando o conhecimento e a experiência da TIS no planeamento e gestão da mobilidade e a capacidade tecnológica da DNXT na construção de uma plataforma de recolha e partilha de informação. No verão de 2023, a DNXT iniciou um processo de joint-venture com uma outra empresa, no âmbito do qual decidiu limitar o seu portfolio de soluções, e por isso, optou por transferir a sua componente deste projeto para a TIS. Atualmente, esta é uma solução 100% desenvolvida pela TIS, a qual recorre a outsourcing para o desenvolvimento da solução tecnológica."}
                />
            </section>

            <section id={"section2"}>
                <a href={"https://www.tis.pt/"} target={"_blank"} rel={"noreferrer"}>
                    <Jumbotron
                        title={{tag: "h2", text: ""}}
                        subtitle={{tag: "h1", text: "TIS"}}
                        desc={"A TIS é uma empresa portuguesa cuja missão passa por “Criar soluções de mobilidade e de acessibilidade eficientes, inovadoras e adequadas às necessidades das pessoas e dos territórios”. <br /><br />No dia a dia, procuramos desenvolver soluções que contribuam para uma maior sustentabilidade e inclusão, incorporando em cada projeto, o nosso conhecimento e know how, mas também a pesquisa do estado da arte e de novas soluções que respondem eficazmente aos desafios colocados.<br /><br />É este o contexto em que foi pensado o projeto da Mobilidade Escolar, no qual procurámos pôr o nosso conhecimento técnico e experiência ao serviço das autarquias, mas fugindo da abordagem de consultoria tradicional e fomentando a implementação independente por parte das autarquias.<br /><br />Esperamos que com este projeto, as autarquias possam acelerar a implementação de uma estratégia de intervenção na mobilidade escolar, focada nas necessidades da comunidade escolar (e expetativas de alunos e de encarregados de educação) que contribua para uma menor dependência do automóvel por parte das crianças e jovens."}
                        img={{src: tisBanner, alt: "TIS", position: "left"}}
                    />
                </a>
            </section>
        </div>
    );
}

export default QuemSomos;
