import React, {useEffect, useState} from 'react';
import data from './data.json';
import {Typography} from '@mui/material';
import parse from 'html-react-parser';
import './Solucoes.scss'
import iconDiagnostic from '../../../assets/icons/diagnostic.png';
import iconSolutions from '../../../assets/icons/solution.png';
import iconCheckList from '../../../assets/icons/check-list.png';
import reparticaoModalJSON from '../../public/mobilidade-escolar/reparticao-modal-2011.json';

interface dadosToolkit {
    title: string,
    data: [
        {
            id: number,
            title?: string,
            text: string,
            solucaoId ?: number,
            acaoId ?: number
        }
    ]
}

const Solucoes = () => {
    const [solucaoId, setSolucaoId] = useState<number>();
    const [acaoId, setAcaoId] = useState<number>();
    const [activeId, setActiveId] = useState<number>();

    useEffect(() => {
        document.body.className = "page-solucoes";

        return () => {
            document.body.className = "";
        }
    }, []);

    const handleClick = (itemId:number) => {
        setActiveId(itemId);
        const itemData = (data[0].data).find(element => element.id === itemId);
       // (itemData && itemData.solucaoId) && setSolucaoId(itemData.solucaoId);

        // @ts-ignore
        const solucao = itemData.solucaoId;
        // @ts-ignore
        const acao = itemData.acaoId;

        setSolucaoId(solucao);
        setAcaoId(acao);
    }

    return (
        <>
            <Typography sx={{color: "#808191", fontWeight: 400, marginBottom: "20px"}}>Selecione o problema encontrado e disponibilizaremos soluções e fichas de ação para a sua resolução.</Typography>

            <section id={"section1"}>
                {
                    data &&
                    <div className={"toolkit-table"}>
                        <div className={"col col-1"}>
                            <div className={"col-title-wrapper"}>
                                <Typography className={"col-title"} variant={"h4"}>{parse(data[0].title)}</Typography>
                                <img className={"icon"} src={iconDiagnostic} alt={data[0].title}/>
                            </div>
                            {
                                data[0].data.map((item) => (
                                    <div key={item.text} className={`col-item ${activeId === item.id ? 'active' : ''}`} onClick={()=>handleClick(item.id)} data-id={item.id}>
                                        <Typography>{parse(item.text)}</Typography>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={"col col-2"}>
                            <div className={"col-title-wrapper"}>
                                <Typography className={"col-title"} variant={"h4"}>{parse(data[1].title)}</Typography>
                                <img className={"icon"} src={iconSolutions} alt={data[1].title}/>
                            </div>
                            {
                                data[1].data.map((item) => (
                                    <div key={item.text} className={`col-item ${solucaoId === item.id ? 'active' : ''}`}>
                                        <Typography>{parse(item.text)}</Typography>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={"col col-3"}>
                            <div className={"col-title-wrapper"}>
                                <Typography className={"col-title"} variant={"h4"}>{parse(data[2].title)}</Typography>
                                <img className={"icon"} src={iconCheckList} alt={data[2].title}/>
                            </div>
                            {
                                data[2].data.map((item) => (
                                    <div key={item.text} className={`col-item ${acaoId === item.id ? 'active' : ''}`}>
                                        {//@ts-ignore
                                            item.title && <Typography className={"title"}><span>{parse(item.title)}</span></Typography>}
                                        <Typography>{parse(item.text)}</Typography>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

export default Solucoes;
