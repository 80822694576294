import React from 'react';
import {Link} from 'react-router-dom';
import {NavLinkProps} from '../components/Navigation/menu';

export const NavLink = (props: NavLinkProps) => (
    <Link to={props.link}
          title={props.text}
          className={props.className}
          onClick={() => {
              document.querySelector("body")!.classList.remove('main-opacity');
              document.querySelector("html")!.classList.remove('menu-open');
          }}>
        {props.children}{props.text}
    </Link>
);
