import React from "react";
import './Footer.scss';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import Copyright from '../../../components/Copyright/Copyright';
import arrow from '../../../../assets/icons/arrow.svg';
import plane from '../../../../assets/icons/plane.svg';
import instagram from '../../../../assets/icons/socials/instagram.png';
import linkedin from '../../../../assets/icons/socials/linkedin.png';
import dnxtLogo from '../../../../assets/media/logo/dnxt-logo.png';
import tisLogo from '../../../../assets/media/logo/tis-logo.png';


const Footer = () => {
    return (
        <footer className={"page-footer"}>
            <div className={"footer-wrapper container"}>
                <div className={"footer-col"}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<img alt={"Arrow"} src={arrow}/>}
                            aria-controls="about-content"
                            id="about-header"
                        >
                            <Typography className={"summary-title"}>Sobre o projeto</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                A plataforma da Mobilidade Escolar tem como objetivo apoiar os municípios na identificação
                                das necessidades sentidas pelos alunos de cada escola e, simultaneamente, ajudar os municípios
                                na escolha das soluções que melhor se adaptem a cada circunstância, contribuindo para que
                                os alunos se possam deslocar de modo cada vez mais sustentável.
                            </Typography>
                            <div className={"footer-logo"}>
                                <a className={"tis-logo"} href={"https://www.tis.pt/"} target={"_blank"} rel="noreferrer">
                                    <img src={tisLogo} alt={"TIS"} width={"70"} height={"37"} />
                                </a>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className={"footer-col"}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<img alt={"Arrow"} src={arrow}/>}
                            aria-controls="socials-content"
                            id="socials-header"
                        >
                            <Typography className={"summary-title"}>Redes sociais</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Saiba mais sobre nós:
                            </Typography>

                            <div className={"socials-wrapper"}>
                                <a href={"https://www.linkedin.com/company/tispt/"} target={"_blank"} rel="noreferrer">
                                    <img src={linkedin} alt={"LinkedIn"} />
                                </a>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className={"footer-col"}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<img alt={"Arrow"} src={arrow}/>}
                            aria-controls="contacts-content"
                            id="contacts-header"
                        >
                            <Typography className={"summary-title"}>Contactos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Para mais informação sobre este projeto,
                                <br />
                                estes são nossos canais de contacto:
                            </Typography>
                            <a className={"mailto"} href={'/contactos'}>
                                <span className={"icon"}>
                                    <img src={plane} alt={"Mobilidade Escolar"} />
                                </span>
                                Fale connosco
                            </a>
                            <a className={"contact-item"} href={'tel:+351213504400'}>
                                +351 213 504 400
                            </a>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <Copyright />
        </footer>
    );
}

export default Footer;
