import React from 'react';
import Chart from "react-apexcharts";
import {Typography} from '@mui/material';
import {TypeBarChart} from './TypeBarChart';

const BarChart: React.FC<TypeBarChart> = ({chartTitle, labels, data, labelColors, textLabelsColor, width, legendPosition, legendHorizontalAlign }: TypeBarChart) => {
    const chart = {
        options: {
            toolbar: {
                show: false,
            },
            chart: {
                redrawOnParentResize: true,
                offsetX: -20,
                offsetY: 0,
                grid: {
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                events: {
                    mounted: (chart:any) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            legend: {
                show: true,
                fontFamily: "Monteserrat, Open Sans, sans-serif",
                fontSize: '11px',
                offsetY: -5,
                offsetX: 15,
                labels: {
                    colors: textLabelsColor ? textLabelsColor : ['#5c5c5c']
                },
                position: legendPosition ? legendPosition : "right",
                horizontalAlign: legendHorizontalAlign ? legendHorizontalAlign : "center"
            },
            colors: labelColors,
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            fill: {
                opacity: 1
            },
            xaxis: {
                categories: labels
            },
            yaxis: {
                labels: {
                    formatter: (val: any) => val + '%'
                }
            },
            plotOptions: {
                bar: {
                    width: "80%",
                    horizontal: false,
                    columnWidth: '53%',
                },
            }
        },
        series: data
    }

    return (
        <div className={"chart-inner"}>
            {chartTitle && <Typography className={"chart-title"}>{chartTitle}</Typography>}
            <Chart
                options={chart.options}
                series={chart.series}
                type="bar"
                width={width}
                height={255}
            />
        </div>
    )
}

export default BarChart;
