import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Sidebar from './Sidebar/Sidebar';
import './Layout-Private.scss';
import {Outlet} from 'react-router-dom';

function LayoutPrivate({children}: any) {
    return (
        <div className="App -private">
            <div className={"page-wrapper"}>
                <Sidebar/>
                <div className={"column main"}>
                    <Header/>
                    <main className={"page-main"}>
                        <Outlet/>
                    </main>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default LayoutPrivate;
