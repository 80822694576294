import React from 'react';
import Card from '../../../common/components/Card/Card';
import Banner from '../../../common/components/Banner/Banner';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import bannerDesktop from '../../../assets/media/public/home/bannerDesktop.jpg';
import bannerMobile from '../../../assets/media/public/home/bannerMobile.jpg';
import iconBicycle from '../../../assets/media/public/home/bicycle.png';
import iconCar from '../../../assets/media/public/home/car.png';
import iconStop from '../../../assets/media/public/home/stop.png';
import iconStudent from '../../../assets/media/public/home/student.png';
import iconWalk from '../../../assets/media/public/home/walk.png';

const Home = () => {
    return (
        <div className={"cms-page home"}>
            <section id={"section1"}>
                <Banner img={{srcDesktop: bannerDesktop, srcMobile: bannerMobile, alt: 'Mobilidade Escolar'}}
                        title={"PARA TODOS"}
                        desc={"Construir um futuro em que as crianças se desloquem de modo sustentável"}
                        btn={{text: "Conhecer o projeto", link: "/sobre", type: "primary"}}/>
            </section>
            <section id={"section2"}>
                <Swiper
                    className={"justMobile"}
                    modules={[Navigation]}
                    navigation={true}
                    breakpoints={{
                        994: {
                            width: 994,
                            slidesPerView: 5,
                        },
                        500: {
                            width: 500,
                            slidesPerView: 1
                        }
                    }}
                    slidesPerView={1}
                >
                    <SwiperSlide key={0}>
                        <Card icon={{src: iconStudent, alt: ""}} backgroundColor={'#8ACCE1'}
                              title={{tag: 'h3', text: '+ 1.4 MILHÕES'}} desc={"DE CRIANÇAS COM 5 A 19 ANOS"}
                              textAlign={"center"}/>
                    </SwiperSlide>
                    <SwiperSlide key={1}>
                        <Card icon={{src: iconCar, alt: ""}} backgroundColor={'#F2BA32'}
                              title={{tag: 'h3', text: '47%'}}
                              desc={"VÃO DE CARRO PARA A ESCOLA"}
                              textAlign={"center"}/>
                    </SwiperSlide>
                    <SwiperSlide key={2}>
                        <Card icon={{src: iconBicycle, alt: ""}} backgroundColor={'#81CBA1'}
                              title={{tag: 'h3', text: 'MENOS DE 0,5%'}}
                              desc={"VÃO DE BICICLETA"}
                              textAlign={"center"}/>
                    </SwiperSlide>
                    <SwiperSlide key={3}>
                        <Card icon={{src: iconWalk, alt: ""}} backgroundColor={'#DD7E55'}
                              title={{tag: 'h3', text: '23%'}}
                              desc={"VÃO A PÉ PARA A ESCOLA"}
                              textAlign={"center"}/>
                    </SwiperSlide>
                    <SwiperSlide key={4}>
                        <Card icon={{src: iconStop, alt: ""}} backgroundColor={'#C15CE5'}
                              title={{tag: 'h3', text: '29%'}}
                              desc={"VÃO DE TRANSPORTE PÚBLICO"}
                              textAlign={"center"}/>
                    </SwiperSlide>
                </Swiper>
            </section>
        </div>
    );
}

export default Home;
