import React from 'react';
import logo from '../../../../assets/media/logo/logo-private.png';
import {Link, NavLink} from 'react-router-dom';
import {useKeycloak} from "@react-keycloak/web";
import {useNavigate} from "react-router-dom";
import './Sidebar.scss';
import iconMunicipio from '../../../../assets/icons/municipio.png';
import iconDiagnostico from '../../../../assets/icons/diagnostico.png';
import iconSolucoes from '../../../../assets/icons/solucoes.png';
import iconMarketplace from '../../../../assets/icons/marketplace.png';
import iconEstimativas from '../../../../assets/icons/estimativa.png';
import iconInqueritos from '../../../../assets/icons/inqueritos.png';
import iconResultados from '../../../../assets/icons/resultados.png';
import iconSearch from '../../../../assets/icons/search.svg';
import iconDefinicoes from '../../../../assets/icons/settings.svg';
import iconMensagem from '../../../../assets/icons/message.svg';
import iconTooltip from '../../../../assets/icons/tooltip.svg';
import iconLogout from '../../../../assets/icons/log-out.svg';

const Sidebar = () => {
    const {keycloak, initialized} = useKeycloak();
    const nav = useNavigate();

    const handleLogout = () => {
        keycloak.logout();
    }

    return (
        <div className={"sidebar"}>
            <div className={"inner"}>
                <div className={"logo"}>
                    <Link to={"/dashboard"}>
                        <img src={logo} alt={"Mobilidade Escolar"}/>
                    </Link>
                </div>
                <div className={"search-wrapper"}>
                    <form id={"mini-search"} className={"form-wrapper form"} onSubmit={(e)=> {e.preventDefault()}}>
                        <div className={"field"}>
                            <input type={"text"} placeholder={"Pesquisar..."}/>
                        </div>
                        <div className={"actions"}>
                            <button type={"submit"} className={"submit"}>
                                <img src={iconSearch} alt={"Pesquisar"}/>
                            </button>
                        </div>
                    </form>
                </div>

                <div className={"navigation"}>
                    <div className={"field"}>
                        <p className={"field-title"}>Situação Atual</p>
                        <NavLink to={'./municipio'} className={"item"}>
                            <div className={"icon"}>
                                <img src={iconMunicipio} alt={"Município"} width={40} height={40}/>
                            </div>
                            Município
                        </NavLink>
                        <NavLink to={'./diagnostico'} className={"item"}>
                            <div className={"icon"}>
                                <img src={iconDiagnostico} alt={"Diagnóstico"} width={40} height={40}/>
                            </div>
                            Diagnóstico
                        </NavLink>
                    </div>
                    <div className={"field"}>
                        <p className={"field-title"}>Soluções para mudar</p>
                        <NavLink to={'./solucoes'} className={"item"}>
                            <div className={"icon"}>
                                <img src={iconSolucoes} alt={"Toolkit de Soluções"} width={40} height={40}/>
                            </div>
                            Toolkit de Soluções
                        </NavLink>
                    </div>
                    <div className={"field"} style={{display: "none"}}>
                        <p className={"field-title"}>Agir e Intervir</p>
                        <NavLink to={'./marketplace'} className={"item"}>
                            <div className={"icon"}>
                                <img src={iconMarketplace} alt={"Marketplace de empresas"} width={40} height={40}/>
                            </div>
                            Marketplace de empresas
                        </NavLink>
                        <NavLink to={'./estimativas'} className={"item"}>
                            <div className={"icon"}>
                                <img src={iconEstimativas} alt={"Estimativas de investimento"} width={40} height={40}/>
                            </div>
                            Estimativas de investimento
                        </NavLink>
                    </div>
                    <div className={"field"} style={{display: "none"}}>
                        <p className={"field-title"}>Avaliar e monitorizar</p>
                        <NavLink to={'./inqueritos'} className={"item"}>
                            <div className={"icon"}>
                                <img src={iconInqueritos} alt={"Inquéritos à população escolar"} width={40}
                                     height={40}/>
                            </div>
                            Inquéritos à <br/> população escolar
                        </NavLink>
                        <NavLink to={'./monitorizacao'} className={"item"}>
                            <div className={"icon"}>
                                <img src={iconResultados} alt={"Monitorização aos resultados"} width={40} height={40}/>
                            </div>
                            Monitorização <br/>aos resultados
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className={"toolbar"}>
                <Link to={"#"}>
                    <img src={iconDefinicoes} alt={"Definições"}/>
                </Link>
                <Link to={"#"}>
                    <img src={iconTooltip} alt={"Tooltip"}/>
                </Link>
                <Link to={"#"}>
                    <img src={iconMensagem} alt={"Mensagens"}/>
                </Link>
                <button onClick={() => handleLogout()}
                        title={"Terminar Sessão"}
                        style={{border: 0, minWidth: "unset", padding: 0, backgroundColor: "transparent", cursor: "pointer", marginTop: "-12px"}}>
                    <img src={iconLogout} alt={"Terminar Sessão"}/>
                </button>
            </div>
        </div>
    );
}

export default Sidebar;
