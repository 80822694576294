import React from 'react';
import {Container, Typography} from '@mui/material';

const PoliticaPrivacidade = () => {
    return (
        <div className={"text-page tos"}>
            <Container className={"container"}>
                <Typography variant={"h1"}>
                    Política de Privacidade
                </Typography>
                <Typography>
                    Lörem ipsum hyponang kavis. Epivavis bel infrase kubinade de tritos. Eusk metates tira. Tritet laröpylogi ninde gyna häse. Dos pyvysk rear. Tiliga dökåsk.
                    Nakuning nelar. Jöng nalogi. Teng vism betynat digt döl. Don epil, däre. Nyns valedes samt naturvin pyhäbening bekymringssamtal. Decidoling didok. Du kan vara drabbad. Gukuns stenorad lakövis. Jande fada ant. Dedinade kuddbok spektig. Sengen häteheten intraktig och tyskade förutom statyprotest. Al defaskade. Petrepp.
                    Doprenade telejehet inte denas som dins. Vys resat antitin, solig, trasorat. Derad kåjins krongar ontoskop huruvida kyn. Vafande mansplaining att härgjord kår sunde. Gihaska ahädat, dängen, sed i sas. Vade paranas att kuvaning.
                </Typography>
                <Typography variant={"h3"}>
                    Subtitulo 1
                </Typography>
                <Typography>
                    Lörem ipsum hyponang kavis. Epivavis bel infrase kubinade de tritos. Eusk metates tira. Tritet laröpylogi ninde gyna häse. Dos pyvysk rear. Tiliga dökåsk.
                    Nakuning nelar. Jöng nalogi. Teng vism betynat digt döl. Don epil, däre. Nyns valedes samt naturvin pyhäbening bekymringssamtal. Decidoling didok. Du kan vara drabbad. Gukuns stenorad lakövis. Jande fada ant. Dedinade kuddbok spektig. Sengen häteheten intraktig och tyskade förutom statyprotest. Al defaskade. Petrepp.
                    Doprenade telejehet inte denas som dins. Vys resat antitin, solig, trasorat. Derad kåjins krongar ontoskop huruvida kyn. Vafande mansplaining att härgjord kår sunde. Gihaska ahädat, dängen, sed i sas. Vade paranas att kuvaning.
                </Typography>
                <Typography variant={"h3"}>
                    Subtitulo 2
                </Typography>
                <Typography>
                    Lörem ipsum hyponang kavis. Epivavis bel infrase kubinade de tritos. Eusk metates tira. Tritet laröpylogi ninde gyna häse. Dos pyvysk rear. Tiliga dökåsk.
                    Nakuning nelar. Jöng nalogi. Teng vism betynat digt döl. Don epil, däre. Nyns valedes samt naturvin pyhäbening bekymringssamtal. Decidoling didok. Du kan vara drabbad. Gukuns stenorad lakövis. Jande fada ant. Dedinade kuddbok spektig. Sengen häteheten intraktig och tyskade förutom statyprotest. Al defaskade. Petrepp.
                    Doprenade telejehet inte denas som dins. Vys resat antitin, solig, trasorat. Derad kåjins krongar ontoskop huruvida kyn. Vafande mansplaining att härgjord kår sunde. Gihaska ahädat, dängen, sed i sas. Vade paranas att kuvaning.
                </Typography>
            </Container>
        </div>
    );
}

export default PoliticaPrivacidade;
