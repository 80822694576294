import {Container} from '@mui/material';
import React from 'react';
import Jumbotron from '../../../common/components/Jumbotron/Jumbotron';
import banner1 from '../../../assets/media/public/sobre/banner1.jpg'
import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import Card from '../../../common/components/Card/Card';
import iconDiagnostic from '../../../assets/icons/diagnostic.png';
import iconSolutions from '../../../assets/icons/solution.png';
import iconCheckList from '../../../assets/icons/check-list.png';
import './Sobre.scss';
import visionIcon from '../../../assets/icons/vision.png';
import targetIcon from '../../../assets/icons/target.png';

const Sobre = () => {
    return (
        <div className={"cms-page sobre"}>
            <Container className={"container"}>
                <section id={"section1"}>
                    <Jumbotron
                        title={{tag: "h2", text: "Sobre o projeto"}}
                        subtitle={{tag: "h1", text: "Como é que surgiu a ideia deste projeto?"}}
                        desc={"De há uns anos para cá, fomos percebendo que a mobilidade das crianças e jovens estava cada vez mais dependente dos pais (ou outros encarregados de educação) e do carro. Esta realidade começou por ser verdade para as crianças em idade pré-escolar, mas foi-se estendendo-se às outras idades.<br/><br/>" +
                            "\n" +
                            "Neste momento está a crescer uma geração de futuros adultos que não conhece outro modo que não seja o carro, o que limita muito as opções modais consideradas e potencia problemas de saúde e de bem-estar, associados ao seu sedentarismo.<br/><br/>" +
                            "\n" +
                            "Para as nossas cidades e vilas, esta forte dependência do automóvel cria desafios significativos à sua gestão, sobretudo num contexto em que é imperioso promover uma maior sustentabilidade dos transportes e a sua descarbonização. Não é por acaso que todos nós temos a capacidade de reconhecer diferenças no tráfego rodoviário quando estamos (ou não) em período escolar."}
                        img={{src: banner1, alt: "Sobre o projeto", position: "right"}}
                    />
                </section>

                <section id={"section2"} >
                    <div className={"result cards-wrapper over-border"}>
                        <Card textAlign={'left'}
                              title={{tag:'h5', text: "Qual a nossa visão?"}}
                              desc={"Garantir que, até 2030, todos os alunos com mais de 10 anos são autónomos e possam escolher modos sustentáveis na sua deslocação casa-escola."}
                              icon={{src: visionIcon, alt: "Visão"}}
                        />
                        <Card textAlign={'left'}
                              title={{tag:'h5', text: "Qual a nossa missão?"}}
                              desc={"Ajudar todas as autarquias a ter a informação e os instrumentos necessários à implementação de uma estratégia de mobilidade escolar que promova uma maior sustentabilidade, de forma autónoma e independente."}
                              icon={{src: targetIcon, alt: "Missão"}}
                        />
                    </div>
                </section>

                <section id={"section3"}>
                    <Jumbotron
                        title={{tag: "h2", text: "Sobre o projeto"}}
                        subtitle={{tag: "h1", text: "Como é que podemos ajudar?"}}
                        desc={"Podemos ajudar a desenvolver uma estratégia de intervenção baseada na compreensão das " +
                            "necessidades dos alunos (e encarregados de educação) de cada escola do concelho, com a qual" +
                            " se promova a transferência modal para os modos ativos e transporte público. Para tal, " +
                            "propõe-se uma abordagem baseada em 3 fases, respetivamente de:"}
                    />

                    <Swiper
                        className={"justMobile cards"}
                        spaceBetween={25}
                        modules={[Pagination]}
                        pagination={{clickable: true}}
                        breakpoints={{
                            994: {
                                width: 994,
                                slidesPerView: 3,
                            },
                            500: {
                                width: 500,
                                slidesPerView: 1
                            }
                        }}
                        slidesPerView={1}
                    >
                        <SwiperSlide key={0}>
                            <Card icon={{src: iconDiagnostic, alt: "", position: "bottom", positionHorizontal: "left"}}
                                  title={{tag: 'h3', text: 'Diagnóstico'}}
                                  list={[
                                      "Conhecer os padrões de mobilidade em cada escola e as razões associadas",
                                      "Identificar os pontos de melhoria sentidos como necessários para que a alteração modal ocorra"
                                  ]}
                                  textAlign={"left"}
                                  backgroundColor={"#DD7E55"}
                            />
                        </SwiperSlide>
                        <SwiperSlide key={1}>
                            <Card icon={{src: iconSolutions, alt: "", position: "bottom", positionHorizontal: "left"}}
                                  title={{tag: 'h3', text: 'Soluções para mudar Agir e intervir'}}
                                  textAlign={"left"}
                                  list={[
                                      "Identificar as soluções que podem contribuir para a mudança, focadas na resolução dos problemas sentidos",
                                      "Diferentes tipos de soluções para diferentes problemas e contextos",
                                      "Fichas de soluções",
                                      "Marketplace"
                                  ]}
                                  backgroundColor={"#8ACCE1"}
                            />
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <Card icon={{src: iconCheckList, alt: "", position: "bottom", positionHorizontal: "left"}}
                                  title={{tag: 'h3', text: 'Avaliar e Monitorizar'}}
                                  list={[
                                      "Inquérito de satisfação com as mudanças",
                                      "Histórico de intervenção e de investimento",
                                      "Inquéritos à mobilidade em anos consecutivos"
                                  ]}
                                  textAlign={"left"}
                                  backgroundColor={"#E6C15D"}
                            />
                        </SwiperSlide>
                    </Swiper>
                </section>
            </Container>
        </div>
    )
}

export default Sobre;
