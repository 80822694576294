import React, { Fragment } from "react";
import {menu} from "./menu";
import './Navigation.scss';
import {NavLink} from '../../utilities/NavLink';

const Navigation = () => {
    const toggleSubmenu = (elem: any) => {
        elem.target.parentElement.parentElement.classList.toggle('show');
    }

    return (
        <nav className={'navigation'}>
            <ul className={'main-menu'}>
                {menu.map(((item, key) => (
                        <li className={'menu-item' + (item.submenu ? ' has-submenu' : '')} key={key}>
                                <NavLink 
                                    link={item.link}
                                    text={item.text}
                                    className={'nav-link'}
                                />

                            {item.submenu &&
                                <>
                                    <button className={'toggle-submenu'} onClick={toggleSubmenu.bind(this)}>
                                        <img className="arrow-down" src={'arrowDown'} alt={'Toggle Submenu'} width={21} height={21} />
                                        <img className="arrow-up" src={'arrowUp'} alt={'Toggle Submenu'} width={21} height={21} />
                                    </button>
                                    <ul className={'submenu'}>
                                        {item.submenu.map(((subItem, subKey) => (
                                            <li className={'submenu-item'} key={subKey}>

                                                <NavLink
                                                    link={subItem.link}
                                                    text={subItem.text}
                                                    className={'nav-link'}
                                                    >
                                                    {
                                                        subItem.icon ?
                                                            <div className={'submenu-icon'}>
                                                                <img src={subItem.icon} alt={'Submenu Icon'} />
                                                            </div>
                                                            :
                                                            <Fragment />
                                                    }
                                                </NavLink>
                                            </li>
                                        )))}
                                    </ul>
                                </>
                            }
                        </li>
                    ))
                )}
            </ul>
        </nav>
    );
}

export default Navigation;
