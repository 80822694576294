import React from 'react';
import {RoutesInterface} from './routesInterface';
import Home from '../areas/public/home/Home';
import MobilidadeEscolar from '../areas/public/mobilidade-escolar/MobilidadeEscolar';
import PoliticaPrivacidade from '../areas/public/politica-privacidade/PoliticaPrivacidade';
import Contactos from '../areas/public/contactos/Contactos';
import QuemSomos from '../areas/public/quem-somos/QuemSomos';
import Sobre from '../areas/public/sobre/Sobre';
import TOS from '../areas/public/tos/TOS';

const publicRoutes:RoutesInterface[] = [
    {
        path: '/',
        element: <Home/>
    },
    {
        path: 'mobilidade-escolar',
        element: <MobilidadeEscolar/>
    },
    {
        path: '/sobre',
        element: <Sobre/>,
    },
    {
        path: 'quem-somos',
        element: <QuemSomos/>
    },
    {
        path: 'contactos',
        element: <Contactos/>
    },
    {
        path: 'termos-condicoes',
        element: <TOS/>
    },
    {
        path: 'politica-privacidade',
        element: <PoliticaPrivacidade />
    }
];

export default publicRoutes;
