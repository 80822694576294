import React from 'react';
import {TypePieChart} from './TypePieChart';
import Chart from "react-apexcharts";
import {Typography} from '@mui/material';


const PieChart:React.FC<TypePieChart> = ({chartTitle, labels, data, type, labelColors, textLabelsColor, width, legendPosition}:TypePieChart) => {
    const chart = {
        options: {
            toolbar: {
                show: false
            },
            chart: {
                redrawOnParentResize: true,
                offsetX: -15,
                offsetY: 0,
                events: {
                    mounted: (chart:any) => {
                        chart.windowResizeHandler();
                    }
                }
            },
            dataLabels: {
                textAnchor: "center",
                offsetX: -10,
                style: {
                    fontSize: "14px",
                },
                dropShadow: {
                    opacity: 0.9
                }
            },
            legend: {
                show: true,
                offsetY: 50,
                fontFamily: "Monteserrat, Open Sans, sans-serif",
                fontSize: '11px',
                labels: {
                    colors: textLabelsColor ? textLabelsColor : ['#5c5c5c'],
                },
                position: legendPosition ? legendPosition : "right",
            },
            labels: labels,
            colors: labelColors,
            stroke: {
                width: 2
            },
            plotOptions: {
                pie: {
                    opacity: 1
                }
            },
            responsive: [{
                breakpoint: 1200,
                options: {
                    chart: {
                        height: 235
                    }
                }
            }]
        },
        series: data
    }

    return (
        <div className={"chart-inner"}>
            {chartTitle && <Typography className={"chart-title"}>{chartTitle}</Typography>}
            <Chart
                options={chart.options}
                series={chart.series}
                type={type ? type : "pie"}
                width={"100%"}
                height={240}
            />
        </div>
    )
}

export default PieChart;
