import React, {useRef, useState} from 'react';
import Jumbotron from '../../../common/components/Jumbotron/Jumbotron';
import {Button, Container} from '@mui/material';
import {SubmitHandler, useForm} from 'react-hook-form';
import Alert from '../../../common/components/Alert/Alert';
import ReCAPTCHA from 'react-google-recaptcha';

interface InterfaceContactForm {
    name: string;
    telephone: string;
    email: string;
    subject: string;
    message: string;
}

const Contactos = () => {
    const {register, formState: {errors}, handleSubmit, reset} = useForm<InterfaceContactForm>();
    const [alert, setAlert] = useState<any>('');
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const onSubmit: SubmitHandler<InterfaceContactForm> = data => {
        try {
            setAlert(<Alert background={"rgb(129, 203, 161)"} timer={15000} message={"Mensagem enviada com sucesso."} />)
            reset();
        } catch (e) {
            setAlert(<Alert background={"rgb(244, 82, 82)"} timer={15000} message={"Ocorreu um erro, por favor tente novamente."} />)
        }

        recaptchaRef.current?.reset();
    }

    return (
        <div className={"cms-page contactos"}>
            <Container className={"container"}>
                {alert}

                <section id={"section1"}>
                    <Jumbotron
                        title={{tag: "h2", text: "Contactos"}}
                        subtitle={{tag: "h1", text: "Fale connosco"}}
                        desc={"Para mais informação sobre este projeto, deixe os seus contatos e em breve receberá mais informação sobre o projeto."}
                    />

                    <div className={"form-wrapper"}>
                        <form id={"form-contact"} className={"contact form"} onSubmit={handleSubmit(onSubmit)}>
                            <div className={`field name ${errors.name ? 'error' : ''}`}>
                                <label htmlFor={"contact-name"}>
                                    Nome*
                                </label>
                                <input type={"text"}
                                       id={"contact-name"}
                                       {...register("name", {required: true})}
                                />
                            </div>
                            <div className={"field telephone"}>
                                <label htmlFor={"contact-telephone"}>
                                    Telefone*
                                </label>
                                <input type={"tel"}
                                       id={"contact-telephone"} {...register("telephone", {required: true})}/>
                            </div>
                            <div className={"field email"}>
                                <label htmlFor={"contact-email"}>
                                    Email*
                                </label>
                                <input type={"email"} id={"contact-email"} {...register("email", {required: true})}/>
                            </div>
                            <div className={"field subject"}>
                                <label htmlFor={"contact-subject"}>
                                    Assunto*
                                </label>
                                <input type={"text"} id={"contact-subject"} {...register("subject", {required: true})}/>
                            </div>
                            <div className={"field message"}>
                                <label htmlFor={"contact-message"}>
                                    Descrição*
                                </label>
                                <textarea id={"contact-message"} rows={15} {...register("message", {required: true})}>

                            </textarea>
                            </div>

                            <ReCAPTCHA
                                style={{marginBottom: "10px"}}
                                ref={recaptchaRef}
                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                            />

                            <div className={"actions"}>
                                <Button variant={"contained"} className={"submit"} type={"submit"}
                                        id={"contact-submit"}>
                                    Submit Form
                                </Button>
                            </div>
                        </form>
                    </div>
                </section>
            </Container>
        </div>
    );
}

export default Contactos;
