import React, {useEffect} from 'react';

const Monitorizacao = () => {
    useEffect(() => {
        document.body.className = "page-monitorizacao";

        return () => {
            document.body.className = "";
        }
    }, []);

    return (
        <h2>
            Monitorizacao
        </h2>
    )
}

export default Monitorizacao;
