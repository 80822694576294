import * as React from 'react';
import {styled} from '@mui/material/styles';
import RadioGroup, {useRadioGroup} from '@mui/material/RadioGroup';
import FormControlLabel, {
    FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import {useState} from 'react';

interface StyledFormControlLabelProps extends FormControlLabelProps {
    checked: boolean;
}

interface RadioButtonProps {
    data: string[],
    handleChange: (param: any) => void
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
    <FormControlLabel {...props} />
))(({checked}) => ({
    '.MuiFormControlLabel-label': checked && {
        backgroundColor: '#4A5052 !important',
        color: '#fff'
    },
}));

const MyFormControlLabel = (props: FormControlLabelProps) => {
    const radioGroup = useRadioGroup();
    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

const RadioButton = (props: RadioButtonProps) => {

    return (
        <RadioGroup row name="use-radio-group" onChange={event => props.handleChange(event)}>
            {
                props.data.map((value, index) => (
                    <MyFormControlLabel key={value} value={value} label={value} control={<Radio/>}/>
                ))
            }
        </RadioGroup>
    );
}

export default RadioButton;
