import React, {useEffect, useState} from 'react';
import iconBell from '../../../assets/icons/bell.png';
import {Menu, MenuItem, Tooltip, Typography} from '@mui/material';

const Notifications = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [notificacoes, setNotificacoes] = useState<any>([""]);

    useEffect(() => {
        setNotificacoes([
            {title: "Notficação1"},
            {title: "Notficação2"},
            {title: "Notficação3"}
        ]);

    }, []);

    return (
        <div className={"notifications"}>
            <Tooltip title="Notificações">
                <span
                    style={{cursor: 'pointer', position: "relative"}}
                    onClick={handleClick}
                    aria-controls={open ? 'notifications-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    className={`${notificacoes ? 'has-news' : ''} `}
                >
                    <img src={iconBell} alt={"Notificações"}/>
                </span>
            </Tooltip>

            <Menu onClose={handleClose}
                  onClick={handleClose}
                  anchorEl={anchorEl}
                  id={"notifications-menu"}
                  open={open}
                  transformOrigin={{horizontal: 'right', vertical: 'top'}}
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>

                {
                    notificacoes.map((item: any, index: number) => (
                        <MenuItem key={index}>
                            <Typography variant={"h6"}>{item.title}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    );
}
export default Notifications;
