import {NavLink} from '../../utilities/NavLink';
import {Typography} from '@mui/material';
import React from 'react';

const Copyright = () => {
    return (
        <div className={"copyright-wrapper"}>
            <div className={"copyright-inner container"}>
                <div className={"col-left"}>
                    <Typography className={'copyright-text'}>Copyright © DNXT. Todos os direitos reservados</Typography>
                </div>

                <div className={"col-right"}>
                    <NavLink
                        link='/termos-condicoes'
                        className='footer-link copyright-text'
                        text='Termos e Condições'
                    />

                    <NavLink
                        link='/politica-privacidade'
                        className='footer-link copyright-text'
                        text='Politica de Privacidade'
                    />
                </div>
            </div>
        </div>
    );
}

export default Copyright;
