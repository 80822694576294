import {useKeycloak} from '@react-keycloak/web';
import React, {useCallback} from 'react';
import {Button} from '@mui/material';
import {ButtonLink} from './ButtonLink';
import {Navigate} from 'react-router-dom';

const ButtonAuth = () => {
    const {keycloak} = useKeycloak();

    const handleLogin = useCallback(() => {
        keycloak?.login()
    }, [keycloak]);

    if (keycloak?.authenticated)
        return <Navigate to={"/dashboard"} replace/>

    return (
        <>
            {!keycloak.authenticated && (
                <Button variant={"contained"} onClick={() => handleLogin()}>{"Área Reservada"}</Button>
            )}
            {!!keycloak.authenticated && (
                <ButtonLink variant={"contained"} link={"/dashboard"} text={"Área Reservada"}/>
            )}
        </>
    );
}

export default ButtonAuth;
