import {Button, ButtonProps} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {NavLinkProps} from '../components/Navigation/menu';

export const ButtonLink = (props: NavLinkProps & ButtonProps) => {
    let navigate = useNavigate();
    const {link, text, ...rest} = props;
    const handleClick: any = (link: string) => {
        navigate(link);
    }
    return (
        <Button {...rest} onClick={() => {
            handleClick(link)
        }}> {text}
        </Button>);
};
