import dashboardIcon from '../../../../assets/icons/iconMobilidadeEscolar.png';
import municipioIcon from '../../../../assets/icons/iconMunicipio.png';
import diagnosticoIcon from '../../../../assets/icons/iconDiagnostico.png';
import solucoesIcon from '../../../../assets/icons/iconSolucoes.png';


export type PageTitleType = {
    title: string,
    icon: {
        src: string,
        alt: string
    },
    url: string
}

export const pageTitles: { icon: { src: string; alt: string }; title: string; url: string }[] = [
    {
        title: "Dashboard",
        icon: {
            src: dashboardIcon,
            alt: "Dashboard",
        },
        url: "/dashboard"
    },
    {
        title: "Município",
        icon: {
            src: municipioIcon,
            alt: "Município",
        },
        url: "/dashboard/municipio"
    },
    {
        title: "Diagnóstico",
        icon: {
            src: diagnosticoIcon,
            alt: "Diagnóstico",
        },
        url: "/dashboard/diagnostico"
    },
    {
        title: "Soluções por problema",
        icon: {
            src: solucoesIcon,
            alt: "Soluções por problema",
        },
        url: "/dashboard/solucoes"
    }
]
