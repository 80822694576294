import React, {useEffect, useState} from 'react';
import {Button, Container, NativeSelect, Typography} from '@mui/material';
import Jumbotron from '../../../common/components/Jumbotron/Jumbotron';
import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import Card from '../../../common/components/Card/Card';
import banner1 from '../../../assets/media/public/mobilidade-escolar/banner1.jpg';
import banner2 from '../../../assets/media/public/mobilidade-escolar/banner2.jpg';
import banner3 from '../../../assets/media/public/mobilidade-escolar/banner3.jpg';
import concelhos from "./concelhos.json"
import reparticaoModalJSON from "./reparticao-modal-2011.json"
import reparticaoModalJSON2021 from "./reparticao-modal-2021.json"
import mobilidadeCasaTrabalhoJSON from "./mobilidade-casa-trabalho-2011.json";
import mobilidadeCasaTrabalhoJSON2021 from "./mobilidade-casa-trabalho-2021.json";
import dadosConcelhoDimensaoJSON from "./mobilidade-casa-trabalho-comparacao-2011.json";
import dadosConcelhoDimensaoJSON2021 from "./mobilidade-casa-trabalho-comparacao-2021.json";
import dadosConcelhoMobilidadeJSON from "./mobilidade-casa-escola-comparacao-2011.json";
import dadosConcelhoMobilidadeJSON2021 from "./mobilidade-casa-escola-comparacao-2021.json";
import './MobilidadeEscolar.scss'
import PieChart from '../../../common/components/PieChart/PieChart';
import BarChart from '../../../common/components/BarChart/BarChart';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import interventionIcon from '../../../assets/icons/intervention.png';
import thermometerIcon from '../../../assets/icons/thermometer.png';


const MobilidadeEscolar = () => {
    interface dadosPercentuais {
        "concelho": string,
        "pe": number,
        "ti": number,
        "tc": number,
        "bicicleta": number,
        "outros": number
    }

    interface dadosPercentuaisComparativos {
        "concelho": string,
        "pe": number,
        "ti": number,
        "tc": number,
        "bicicleta": number,
        "outros": number,
        "cimPe": number,
        "cimTi": number,
        "cimTc": number,
        "cimBicicleta": number,
        "cimOutros": number
    }

    const [concelho, setConcelho] = useState(concelhos[0].concelho);
    const [reparticaoModal, setReparticaoModal] = useState<dadosPercentuais>({
        concelho: '',
        pe: 0,
        ti: 0,
        tc: 0,
        bicicleta: 0,
        outros: 0
    });
    const [reparticaoModal2021, setReparticaoModal2021] = useState<dadosPercentuais>({
        concelho: '',
        pe: 0,
        ti: 0,
        tc: 0,
        bicicleta: 0,
        outros: 0
    });
    const [mobilidadeCasaTrabalho, setMobilidadeCasaTrabalho] = useState<dadosPercentuais>({
        concelho: '',
        pe: 0,
        ti: 0,
        tc: 0,
        bicicleta: 0,
        outros: 0
    });
    const [mobilidadeCasaTrabalho2021, setMobilidadeCasaTrabalho2021] = useState<dadosPercentuais>({
        concelho: '',
        pe: 0,
        ti: 0,
        tc: 0,
        bicicleta: 0,
        outros: 0
    });
    const [mobilidadeCasaTrabalhoComparacao, setMobilidadeCasaTrabalhoComparacao] = useState<dadosPercentuaisComparativos>({
        concelho: '',
        pe: 0,
        ti: 0,
        tc: 0,
        bicicleta: 0,
        outros: 0,
        cimPe: 0,
        cimTi: 0,
        cimTc: 0,
        cimBicicleta: 0,
        cimOutros: 0
    });
    const [mobilidadeCasaTrabalhoComparacao2021, setMobilidadeCasaTrabalhoComparacao2021] = useState<dadosPercentuaisComparativos>({
        concelho: '',
        pe: 0,
        ti: 0,
        tc: 0,
        bicicleta: 0,
        outros: 0,
        cimPe: 0,
        cimTi: 0,
        cimTc: 0,
        cimBicicleta: 0,
        cimOutros: 0
    });
    const [mobilidadeCasaEscolaComparacao, setMobilidadeCasaEscolaComparacao] = useState<dadosPercentuaisComparativos>({
        concelho: '',
        pe: 0,
        ti: 0,
        tc: 0,
        bicicleta: 0,
        outros: 0,
        cimPe: 0,
        cimTi: 0,
        cimTc: 0,
        cimBicicleta: 0,
        cimOutros: 0
    });
    const [mobilidadeCasaEscolaComparacao2021, setMobilidadeCasaEscolaComparacao2021] = useState<dadosPercentuaisComparativos>({
        concelho: '',
        pe: 0,
        ti: 0,
        tc: 0,
        bicicleta: 0,
        outros: 0,
        cimPe: 0,
        cimTi: 0,
        cimTc: 0,
        cimBicicleta: 0,
        cimOutros: 0
    });
    const labels = ['% a pé', '% Bicicleta', '% TI', '% TC', '% Outros'];
    const labelsComp = ['% a pé', '% TI', '% TC', '% Bicicleta', '% Outros'];
    const reparticaoModalData = [reparticaoModal.pe, reparticaoModal.bicicleta, reparticaoModal.ti, reparticaoModal.tc, reparticaoModal.outros];
    const mobilidadeEscolarData = [mobilidadeCasaTrabalho.pe, mobilidadeCasaTrabalho.bicicleta, mobilidadeCasaTrabalho.ti, mobilidadeCasaTrabalho.tc, mobilidadeCasaTrabalho.outros];
    const mobilidadeCasaTrabalhoComparacaoData = [
        {
            name: 'Concelho',
            data: [mobilidadeCasaTrabalhoComparacao.pe,
                mobilidadeCasaTrabalhoComparacao.ti,
                mobilidadeCasaTrabalhoComparacao.tc,
                mobilidadeCasaTrabalhoComparacao.bicicleta,
                mobilidadeCasaTrabalhoComparacao.outros]
        },
        {
            name: 'Concelho mesma dimensão',
            data: [mobilidadeCasaTrabalhoComparacao.cimPe,
                mobilidadeCasaTrabalhoComparacao.cimTi,
                mobilidadeCasaTrabalhoComparacao.cimTc,
                mobilidadeCasaTrabalhoComparacao.cimBicicleta,
                mobilidadeCasaTrabalhoComparacao.cimOutros]
        }];
    const mobilidadeCasaEscolaComparacaoData = [
        {
            name: 'Concelho',
            data: [mobilidadeCasaEscolaComparacao.pe,
                mobilidadeCasaEscolaComparacao.ti,
                mobilidadeCasaEscolaComparacao.tc,
                mobilidadeCasaEscolaComparacao.bicicleta,
                mobilidadeCasaEscolaComparacao.outros]
        },
        {
            name: 'Concelho CIM',
            data: [mobilidadeCasaEscolaComparacao.cimPe,
                mobilidadeCasaEscolaComparacao.cimTi,
                mobilidadeCasaEscolaComparacao.cimTc,
                mobilidadeCasaEscolaComparacao.cimBicicleta,
                mobilidadeCasaEscolaComparacao.cimOutros]
        }];
    const reparticaoModalData2021 = [reparticaoModal2021.pe, reparticaoModal2021.bicicleta, reparticaoModal2021.ti, reparticaoModal2021.tc, reparticaoModal2021.outros];
    const mobilidadeEscolarData2021 = [mobilidadeCasaTrabalho2021.pe, mobilidadeCasaTrabalho2021.bicicleta, mobilidadeCasaTrabalho2021.ti, mobilidadeCasaTrabalho2021.tc, mobilidadeCasaTrabalho2021.outros];
    const mobilidadeCasaTrabalhoComparacaoData2021 = [
        {
            name: 'Concelho',
            data: [mobilidadeCasaTrabalhoComparacao2021.pe,
                mobilidadeCasaTrabalhoComparacao2021.ti,
                mobilidadeCasaTrabalhoComparacao2021.tc,
                mobilidadeCasaTrabalhoComparacao2021.bicicleta,
                mobilidadeCasaTrabalhoComparacao2021.outros]
        },
        {
            name: 'Concelho mesma dimensão',
            data: [mobilidadeCasaTrabalhoComparacao2021.cimPe,
                mobilidadeCasaTrabalhoComparacao2021.cimTi,
                mobilidadeCasaTrabalhoComparacao2021.cimTc,
                mobilidadeCasaTrabalhoComparacao2021.cimBicicleta,
                mobilidadeCasaTrabalhoComparacao2021.cimOutros]
        }];
    const mobilidadeCasaEscolaComparacaoData2021 = [
        {
            name: 'Concelho',
            data: [mobilidadeCasaEscolaComparacao2021.pe,
                mobilidadeCasaEscolaComparacao2021.ti,
                mobilidadeCasaEscolaComparacao2021.tc,
                mobilidadeCasaEscolaComparacao2021.bicicleta,
                mobilidadeCasaEscolaComparacao2021.outros]
        },
        {
            name: 'Concelho CIM',
            data: [mobilidadeCasaEscolaComparacao2021.cimPe,
                mobilidadeCasaEscolaComparacao2021.cimTi,
                mobilidadeCasaEscolaComparacao2021.cimTc,
                mobilidadeCasaEscolaComparacao2021.cimBicicleta,
                mobilidadeCasaEscolaComparacao2021.cimOutros]
        }];

    const labelColors = ['#4879F5', '#F45252', '#44D2F1', '#20C745', '#E6C15D'];
    const barColors = ['#F45252', '#4879F5'];

    const handleSearch = () => {
        getChartsData();
    }

    const getChartsData = () => {
        const dataReparticaoModal = reparticaoModalJSON.find(element => element.concelho === concelho);
        const dataMobilidadeEscolar = mobilidadeCasaTrabalhoJSON.find(element => element.concelho === concelho);
        const dataConcelhosMsmDimensao = dadosConcelhoDimensaoJSON.find(element => element.concelho === concelho);
        const dataMobilidadeCasaEscolaComparacao = dadosConcelhoMobilidadeJSON.find(element => element.concelho === concelho);
        dataReparticaoModal && setReparticaoModal(dataReparticaoModal);
        dataMobilidadeEscolar && setMobilidadeCasaTrabalho(dataMobilidadeEscolar);
        dataConcelhosMsmDimensao && setMobilidadeCasaTrabalhoComparacao(dataConcelhosMsmDimensao);
        dataMobilidadeCasaEscolaComparacao && setMobilidadeCasaEscolaComparacao(dataMobilidadeCasaEscolaComparacao);
        const dataReparticaoModal2021 = reparticaoModalJSON2021.find(element => element.concelho === concelho);
        const dataMobilidadeEscolar2021 = mobilidadeCasaTrabalhoJSON2021.find(element => element.concelho === concelho);
        const dataConcelhosMsmDimensao2021 = dadosConcelhoDimensaoJSON2021.find(element => element.concelho === concelho);
        const dataMobilidadeCasaEscolaComparacao2021 = dadosConcelhoMobilidadeJSON2021.find(element => element.concelho === concelho);
        dataReparticaoModal2021 && setReparticaoModal2021(dataReparticaoModal2021);
        dataMobilidadeEscolar2021 && setMobilidadeCasaTrabalho2021(dataMobilidadeEscolar2021);
        dataConcelhosMsmDimensao2021 && setMobilidadeCasaTrabalhoComparacao2021(dataConcelhosMsmDimensao2021);
        dataMobilidadeCasaEscolaComparacao2021 && setMobilidadeCasaEscolaComparacao2021(dataMobilidadeCasaEscolaComparacao2021);
    }

    useEffect(() => {
        getChartsData();
    }, []);

    return (
        <div className={"cms-page mobilidade-escolar"}>
            <Container className={"container"}>
                <section id={"section1"}>
                    <Jumbotron
                        subtitle={{tag: "h1", text: "Contexto atual"}}
                        desc={"Vivemos em cidades e vilas onde só muito raramente se veem crianças a andar sozinhas a caminho da escola; a cada vez maior dependência das famílias relativamente ao automóvel privado estendeu-se a todas as gerações, gerando um círculo vicioso em que os pais acham que a rua é perigosa para os seus filhos e, na verdade, são também contribuintes para o aumento da insegurança com os seus comportamentos desregrados de circulação rodoviária e de estacionamento.\n" +
                            "<br/><br/>" +
                            "É necessário mudar a mobilidade escolar se queremos crianças mais saudáveis e felizes, mas também porque as cidades não podem continuar a suportar a pressão que a dependência face ao automóvel está a exercer sobre estas.\n" +
                            "Acreditamos que é possível construir um futuro no qual os pais possam voltar a deixar os filhos irem sozinhos para a escola, seja em transporte público, bicicleta, trotineta ou a pé. No dia em que isso acontecer de modo generalizado, saberemos que estamos a ir no bom caminho.\n" +
                            "<br/><br/>" +
                            "São inúmeros os benefícios que estão associados à promoção de uma mobilidade escolar sustentável e independente. Entre estes destacam-se os seguintes:"}
                    />
                </section>
                <section id={"section2"}>
                    <Swiper
                        className={"justMobile"}
                        spaceBetween={25}
                        modules={[Pagination]}
                        pagination={{clickable: true}}
                        breakpoints={{
                            994: {
                                width: 994,
                                slidesPerView: 3,
                            },
                            500: {
                                width: 500,
                                slidesPerView: 1
                            }
                        }}
                        slidesPerView={1}
                    >
                        <SwiperSlide key={0}>
                            <Card image={{src: banner1, alt: "", position: "top"}}
                                  title={{tag: 'h3', text: 'Para as crianças'}}
                                  list={[
                                      "Mais ativas e saudáveis",
                                      "Menos dependentes dos pais",
                                      "Com maior autoestima",
                                      "Com maior autonomia"
                                  ]}
                                  textAlign={"left"}
                                  backgroundColor={"#D2746F"}
                                  btn={{type: "secondary", link: "/", text: "Saber Mais"}}
                            />
                        </SwiperSlide>
                        <SwiperSlide key={1}>
                            <Card image={{src: banner2, alt: "", position: "top"}}
                                  title={{tag: 'h3', text: 'Para os pais e encarregados de educação'}}
                                  textAlign={"left"}
                                  list={[
                                      "Menos reféns dos horários escolares",
                                      "Menor dependência da utilização do carro",
                                      "Menos stress e melhor gestão do tempo"
                                  ]}
                                  backgroundColor={"#83BF95"}
                                  btn={{type: "secondary", link: "/", text: "Saber Mais"}}
                            />
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <Card image={{src: banner3, alt: "", position: "top"}}
                                  title={{tag: 'h3', text: 'Para a sociedade'}}
                                  list={[
                                      "Menos congestionamento",
                                      "Menos acidentes rodoviários",
                                      "Maior qualidade de vida",
                                      "Ambiente menos poluído"
                                  ]}
                                  textAlign={"left"}
                                  backgroundColor={"#E6C15D"}
                                  btn={{type: "secondary", link: "/", text: "Saber Mais"}}
                            />
                        </SwiperSlide>
                    </Swiper>
                </section>
                <section id={"section3"}>
                    <Jumbotron
                        title={{tag: "h2", text: "Mobilidade escolar"}}
                        subtitle={{
                            tag: "h1",
                            text: "Sabe como é que os alunos do seu concelho se deslocam para a escola?"
                        }}
                    />

                    <div className={"toolbar search"}>
                        <FormControl sx={{flexDirection: "row", alignItems: "flex-end"}}
                                     className={"form select-concelho"}>
                            <div className={"field"}>
                                <NativeSelect
                                    variant={"outlined"}
                                    inputProps={{
                                        id: "select-concelho"
                                    }}
                                    IconComponent={ExpandMoreIcon}
                                    onChange={e => setConcelho(e.target.value)}
                                >
                                    {concelhos.map((c, i) => (
                                        <option
                                            key={i}
                                            value={c.concelho}
                                        >
                                            {c.concelho}
                                        </option>
                                    ))}
                                </NativeSelect>
                            </div>
                            <div className={"actions"}>
                                <Button type={"submit"} variant={"contained"} onClick={handleSearch}>
                                    Pesquisar
                                </Button>
                            </div>
                        </FormControl>
                    </div>

                    <div className={"result cards-wrapper"}>
                        <Card textAlign={'left'}
                              title={{tag: 'h5', text: "Metas para 2030"}}
                              desc={"Até 2030, cerca de 35% das deslocações devem ser realizadas a pé (meta da Estratégia Nacional para a Mobilidade Ativa Pedonal) e pelo menos, 10% das deslocações nas cidades devem ser em bicicleta (Estratégia Nacional para a Mobilidade Ativa Ciclável)."}
                              icon={{src: thermometerIcon, alt: "Resultados"}}
                        />
                        <Card textAlign={'left'}
                              title={{tag: 'h5', text: "Intervenção prioritária"}}
                              desc={"Quão longe está o seu município de atingir estas metas? Estas metas são ambiciosas e implicam uma mudança rápida dos comportamentos de toda a população, e em particular dos jovens de hoje que serão os adultos de amanhã."}
                              icon={{src: interventionIcon, alt: "Resultados"}}
                        />
                    </div>

                    <div className={"stats-wrapper"}>
                        <div className={"stats-item"}>
                            <div className={"stats-title"}>
                                <Typography variant={"h4"}>Repartição modal</Typography>
                            </div>
                            <div className={"stats"}>
                                <div className={"chart-wrapper"}>
                                    <PieChart
                                        data={reparticaoModalData}
                                        labels={labels}
                                        width={325}
                                        labelColors={labelColors}
                                        chartTitle={"Ano 2011"}
                                    />
                                </div>
                                <div className={"chart-wrapper"}>
                                    <PieChart
                                        data={reparticaoModalData2021}
                                        labels={labels}
                                        width={325}
                                        labelColors={labelColors}
                                        chartTitle={"Ano 2021"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"stats-item"}>
                            <div className={"stats-title"}>
                                <Typography variant={"h4"}>Mobilidade casa-escola</Typography>
                            </div>
                            <div className={"stats"}>
                                <div className={"chart-wrapper"}>
                                    <PieChart
                                        data={mobilidadeEscolarData}
                                        labels={labels}
                                        width={325}
                                        labelColors={labelColors}
                                        chartTitle={"Ano 2011"}
                                    />
                                </div>
                                <div className={"chart-wrapper"}>
                                    <PieChart
                                        data={mobilidadeEscolarData2021}
                                        labels={labels}
                                        width={325}
                                        labelColors={labelColors}
                                        chartTitle={"Ano 2021"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"stats-item"}>
                            <div className={"stats-title"}>
                                <Typography variant={"h4"}>Concelho e Concelhos da mesma CIM</Typography>
                            </div>
                            <div className={"stats"}>
                                <div className={"chart-wrapper typeBar"}>
                                    <BarChart
                                        data={mobilidadeCasaEscolaComparacaoData}
                                        labels={labelsComp}
                                        width={325}
                                        labelColors={barColors}
                                        legendPosition={"bottom"}
                                        legendHorizontalAlign={"left"}
                                        chartTitle={"Ano 2011"}
                                    />
                                </div>
                                <div className={"chart-wrapper typeBar"}>
                                    <BarChart
                                        data={mobilidadeCasaEscolaComparacaoData2021}
                                        labels={labelsComp}
                                        width={325}
                                        labelColors={barColors}
                                        legendPosition={"bottom"}
                                        legendHorizontalAlign={"left"}
                                        chartTitle={"Ano 2021"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"stats-item"}>
                            <div className={"stats-title"}>
                                <Typography variant={"h4"}>Concelho e Concelhos da mesma dimensão</Typography>
                            </div>
                            <div className={"stats"}>
                                <div className={"chart-wrapper typeBar"}>
                                    <BarChart
                                        data={mobilidadeCasaTrabalhoComparacaoData}
                                        labels={labelsComp}
                                        width={325}
                                        labelColors={barColors}
                                        legendPosition={"bottom"}
                                        legendHorizontalAlign={"left"}
                                        chartTitle={"Ano 2011"}
                                    />
                                </div>
                                <div className={"chart-wrapper typeBar"}>
                                    <BarChart
                                        data={mobilidadeCasaTrabalhoComparacaoData2021}
                                        labels={labelsComp}
                                        width={325}
                                        labelColors={barColors}
                                        legendPosition={"bottom"}
                                        legendHorizontalAlign={"left"}
                                        chartTitle={"Ano 2021"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Typography sx={{fontSize: "12px", marginTop: "30px"}}>Fonte: INE, Censos 2011 – Movimentos
                        pendulares<br/></Typography>
                </section>
            </Container>
        </div>
    )
}

export default MobilidadeEscolar;
