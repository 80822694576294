import React, {useEffect, useState} from 'react';
import iconMenu from '../../../assets/icons/menuHamburguer.png';
import './LocalPicker.scss';
import {Typography} from '@mui/material';

interface currentLocal {
    name: string,
    iconSrc: string
}

const LocalPicker = () => {
    const [locals, setLocals] = useState<any>([]);
    const [current, setCurrent] = useState<currentLocal>({iconSrc: '', name: ''});

    useEffect(() => {
        setCurrent({
            iconSrc: '',
            name: 'Torres Vedras'
        })
    }, []);

    return (
        <div className={"picker-wrapper"}>
            <div className={"panel"}>
                <Typography>{current.name}</Typography>

                <span>
                    <img src={iconMenu} alt={""} />
                </span>
            </div>
        </div>
    );
}

export default LocalPicker;
