import React, {useEffect} from 'react';

const Inqueritos = () => {
    useEffect(() => {
        document.body.className = "page-inqueritos";

        return () => {
            document.body.className = "";
        }
    }, []);

    return (
        <h2>
            Inqueritos
        </h2>
    )
}

export default Inqueritos;
