import React, {useEffect} from 'react';

const Marketplace = () => {
    useEffect(() => {
        document.body.className = "page-marketplace";

        return () => {
            document.body.className = "";
        }
    }, []);

    return (
        <h2>
            Marketplace
        </h2>
    )
}

export default Marketplace;
