import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {pageTitles} from './pageTitles';
import {PageTitleType} from './pageTitles';
import iconMobilidadeEscolar from '../../../../assets/icons/iconMobilidadeEscolar.png';
import {Typography} from '@mui/material';

const PageTitle = () => {
    const styles = {
        icon: {
            display: "inline-block",
            verticalAlign: "middle"
        },
        text: {
            display: "inline-block",
            marginLeft: "20px",
            verticalAlign: "middle"
        }
    }

    const location = useLocation();
    const [data, setData] = useState<PageTitleType>(
        {
            title: "",
            icon: {
                src: "",
                alt: ""
            },
            url: ""
        }
    );

    useEffect(() => {
        const pageTitleData = pageTitles.find(element => element.url === location.pathname);
        if (pageTitleData) {
            setData(pageTitleData);
        } else {
            setData({
                title: "Mobilidade Escolar",
                icon: {
                    src: iconMobilidadeEscolar,
                    alt: "Mobilidade Escolar"
                },
                url: ""
            })
        }

    }, [location]);


    return (
        <div className={"page-title-wrapper"}>
            <div className={"icon"} style={styles.icon}>
                <img src={data.icon.src} alt={data.icon.alt} />
            </div>
            <Typography variant={"h4"} style={styles.text}>
                {data.title}
            </Typography>
        </div>
    );
}

export default PageTitle;
