import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import {Outlet} from 'react-router-dom';

function LayoutPublic(props:any) {
    return (
        <div className="App -public">
            <div className={"page-wrapper"}>
                <Header />
                <main className={"page-main"}>
                    <Outlet />
                </main>
                <Footer />
            </div>
        </div>
    );
}

export default LayoutPublic;
