import React from 'react';
import {Typography} from '@mui/material';
import './Jumbotron.scss';
import {jumbotron} from './interfaceJumbotron';
import {ButtonLink} from '../../utilities/ButtonLink';
import parse from 'html-react-parser'
import {Link} from 'react-router-dom';

const Jumbotron: React.FC<jumbotron> = ({title, subtitle, desc, list, btnPrimary, btnSecondary, img, icon}: jumbotron) => {
    return (
        <div className={`jumbotron ${(icon) ? 'type-2' : ''} ${(img && img.position === 'bottom') ? 'type-3' : ''}`}>
            {
                (img && img.position === "left") &&
                <div className={"banner left"}>
                    <img className={"img-banner"} alt={img.alt} src={img.src}/>
                </div>
            }
            <div className={`desc ${!img && 'just-text'}`}>
                {(icon) &&
                    <div className={"icon-wrapper"}>
                        {(icon) && <img alt={icon.alt} src={icon.src} width={26} height={26}/>}
                    </div>
                }

                {title && <Typography className={"title"} variant={title.tag}>{title.text}</Typography>}
                {(subtitle && subtitle.link) ?
                    <a href={subtitle.link.url} target={subtitle.link.target}>
                        <Typography className={"subtitle"} variant={subtitle.tag}>{subtitle.text}</Typography>
                    </a>

                    : subtitle ? <Typography className={"subtitle"} variant={subtitle.tag}>{subtitle.text}</Typography>
                    :
                    ''
                }
                {desc && <Typography className={"text"}>{parse(desc)} </Typography>}

                {
                    list &&
                    <div className={"list-wrapper"}>
                        <ul className={"list"}>
                            {list.map((item, key) => (
                                <li key={key} className={"list-item"}>
                                    {item}
                                </li>
                                ))
                            }
                        </ul>

                    </div>
                }

                {
                    (btnPrimary || btnSecondary) &&
                    <div className={"actions"}>
                        {
                            (btnPrimary) &&
                            <ButtonLink variant={"contained"} text={btnPrimary.text} link={btnPrimary.link} />
                        }
                        {
                            (btnSecondary) &&
                            <ButtonLink variant={"outlined"} text={btnSecondary.text} link={btnSecondary.link} />
                        }
                    </div>
                }
            </div>

            {
                (img && img.position !== "left") &&
                <div className={`banner ${img.position === "right" ? 'right' : 'bottom'}`}>
                    <img alt="DNXT" className={"img-banner"} src={img.src}/>
                </div>
            }
        </div>
    )
}

export default Jumbotron;
