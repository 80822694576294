import {useKeycloak} from "@react-keycloak/web";
import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import LayoutPrivate from '../common/layouts/LayoutPrivate/LayoutPrivate';


const PrivateRoute = ({children}:any) => {
    const {keycloak} = useKeycloak();
    const isLoggedIn = keycloak.authenticated;

    return (isLoggedIn) ? <LayoutPrivate><Outlet /></LayoutPrivate> : <Navigate to={"/"} replace={true} />;
}

export default PrivateRoute;
