import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary:  {
            main: '#DD7E55'
        },
        secondary: {
            main: '#FFFFFF'
        },
        action: {
            active: '#EA2026',
            hover: '#FFFFFF'
        }
    },
    shape: {
        borderRadius: 6
    },
    typography: {
        fontFamily: ["Montserrat", "Helvetica", "Arial", "sans-serif"].join(","),
        htmlFontSize: 16,
        button: {
            fontWeight: 700,
            fontSize: "14px"
        },
        h1: {
            fontSize: "40px",
            fontWeight: 800,
            lineHeight: "50px",
            letterSpacing: "0.5px"
        },
        h2: {
            fontFamily: "'Mouse Memoirs', sans-serif",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "#E6C15D"
        },
        h3: {
            fontSize: "24px",
            fontWeight: 800,
            lineHeight: "30px",
            letterSpacing: "0.5px"
        },
        h4: {
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24px"
        },
        h5: {
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px"
        },
        h6: {
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "16px",
            letterSpacing: "0.5px"
        }
    }
});
