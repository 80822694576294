import React from 'react';
import {CardInterface} from './cardInterface';
import {Typography} from '@mui/material';
import './Card.scss'
import {ButtonLink} from '../../utilities/ButtonLink';

const Card: React.FC<CardInterface> = ({title, desc, list, icon, image, backgroundColor, btn, textAlign}: CardInterface) => {
    return (
        <div
            className={`card ${backgroundColor ? 'has-background' : ''} ${image ? 'has-image -' + image.position : ''}`}
            style={{textAlign: textAlign}}>
            {
                image &&
                <div className={"image-wrapper"}>
                    <img src={image.src} alt={image.alt}/>
                </div>
            }
            <div className={"content-wrapper"}
                 style={backgroundColor ? {backgroundColor: backgroundColor} : {}}>
                {
                    icon ?
                        <div className={`card-icon ${icon.positionHorizontal ? 'align-' + icon.positionHorizontal : ''} ${icon.position ? 'position-' + icon.position : ''} `}>
                            <img src={icon.src} alt={icon.alt} width={icon.width ? icon.width : 'auto'} height={icon.height ? icon.height : 'auto'} />
                        </div>
                        :
                        ''
                }
                {title && <Typography className={"title"} variant={title.tag}>{title.text}</Typography>}
                {desc && <Typography className={"desc"}>{desc}</Typography>}

                {
                    list &&
                    <div className={"list-wrapper"}>
                        <ul className={"list"}>
                            {list.map((item, key) => (
                                <li key={key} className={"list-item"}>
                                    <Typography>{item}</Typography>
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                }

                {
                    btn &&
                    <div className={"actions"}>
                        {(btn && (btn.type === 'primary')) &&
                            <ButtonLink className={"primary"} variant={"contained"} link={btn.link} text={btn.text}/>}
                        {(btn && (btn.type === 'secondary')) &&
                            <ButtonLink className={"secondary"} variant={"outlined"} link={btn.link} text={btn.text}/>}
                    </div>
                }
            </div>

        </div>
    )
}
export default Card;
