import React from 'react';
import {RoutesInterface} from './routesInterface';
import Dashboard from '../areas/private/Dashboard/Dashboard';
import Municipios from '../areas/private/Municipios/Municipios';
import Diagnostico from '../areas/private/Diagnostico/Diagnostico';
import Solucoes from '../areas/private/Solucoes/Solucoes';
import Marketplace from '../areas/private/Marketplace/Marketplace';
import Estimativas from '../areas/private/Estimativas/Estimativas';
import Inqueritos from '../areas/private/Inqueritos/Inqueritos';
import Monitorizacao from '../areas/private/Monitorizacao/Monitorizacao';

const privateRoutes:RoutesInterface[] = [
    {
        path: '/dashboard',
        element: <Dashboard/>
    },
    {
        path: '/dashboard/municipio',
        element: <Municipios/>
    },
    {
        path: '/dashboard/diagnostico',
        element: <Diagnostico />
    },
    {
        path: '/dashboard/solucoes',
        element: <Solucoes />
    },
    {
        path: '/dashboard/marketplace',
        element: <Marketplace />
    },
    {
        path: '/dashboard/estimativas',
        element: <Estimativas />
    },
    {
        path: '/dashboard/inqueritos',
        element: <Inqueritos />
    },
    {
        path: '/dashboard/monitorizacao',
        element: <Monitorizacao />
    }
];

export default privateRoutes;
