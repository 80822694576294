import React, {useEffect, useState} from 'react';
import {
    Box,
    Checkbox, Chip,
    FormControl,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent, Typography
} from '@mui/material';
import RadioButton from '../../../common/components/RadioButton/RadioButton';
import './Diagnostico.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BarChart from '../../../common/components/BarChart/BarChart';
import PieChart from '../../../common/components/PieChart/PieChart';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Diagnostico = () => {
    const [escolas, setEscolas] = useState<string[]>([]);
    const [escolasSelected, setEscolasSelected] = React.useState<string[]>([]);
    const [anosLetivos, setAnosLetivos] = useState<string[]>([]);
    const [anoSelected, setAnoSelected] = useState<string>('');

    const handleChange = (event: SelectChangeEvent<typeof escolas>) => {
        const {
            target: {value},
        } = event;
        setEscolasSelected(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        document.body.className = "page-diagnostico";

        setEscolas([
            'Escola do Centro Escolar Dr. Salgueiro de Almeida',
            'Escola Básica e Secundária D. Filipa de Lencastre',
            'Escola Secundária Vergílio Ferreira',
            'Escola Secundária Camões',
            'Escola Secundária José Gomes Ferreira',
            'Escola Secundária do Restelo'
        ]);

        setAnosLetivos([
            '2011/2012', '2013/2014', '2015/2016', '2017/2018', '2019/2020'
        ]);

        return () => {
            document.body.className = "";
        }
    }, []);

    return (
        <>
            <section id={"section1"} className={"select diagnostic"}>
                <Typography className={"select-title"} variant={"h5"}>
                    Selecione as Escolas
                </Typography>
                <div className={"form-wrapper"}>
                    <FormControl sx={{width: "100%"}}>
                        <div className={"field escola"}>
                            <Select
                                labelId="select-escolas-label"
                                id="select-escolas"
                                multiple
                                value={escolasSelected}
                                IconComponent={ExpandMoreIcon}
                                onChange={handleChange}
                                renderValue={(selected) => (
                                    <Box sx={{display: 'flex', gap: 0.5, overflow: 'hidden'}}>
                                        {selected.map((value) => (
                                            <Chip sx={{
                                                backgroundColor: '#E6E7E7',
                                                borderRadius: '3px',
                                                height: '19px'
                                            }} key={value} label={value}/>
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                sx={{width: "100%"}}
                            >
                                {escolas.map((escola) => (
                                    <MenuItem key={escola} value={escola}>
                                        <Checkbox checked={escolasSelected.indexOf(escola) > -1}/>
                                        <ListItemText primary={escola}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className={"field ano-letivo"}>
                            <RadioButton handleChange={(event) => setAnoSelected(event.target.value)}
                                         data={anosLetivos}/>
                        </div>
                    </FormControl>
                </div>
            </section>
            <section id={"section2"} className={"results"}>
                <div className={"stats-wrapper"}>
                    <div className={"row"}>
                        <div className={"chart-wrapper chart-1"}>
                            <BarChart
                                chartTitle={"# de alunos por Ciclo de Ensino"}
                                data={[{
                                    name: 'Escola Básica 1º CEB...',
                                    data: [3.2, 1.98, 2.76, 3.5]
                                },
                                    {
                                        name: 'EB1 Florbela Espancao',
                                        data: [1.2, 3.38, 4.06, 2.5]
                                    },
                                    {
                                        name: 'EB1 - JI da Barranha',
                                        data: [3.2, 2.38, 1.06, 3.3]
                                    },
                                    {
                                        name: 'Escola do Corpo Santo',
                                        data: [2.7, 3.6, 3.6, 1.2]
                                    }
                                ]}
                                labels={['1º ciclo Ensino Básico', '2º ciclo Ensino Básico', '3º ciclo Ensino Básico', 'Ensino Secundário Profissional']}
                                width={"100%"} labelColors={['#6C5DD3', '#DD7E55', '#20C745', '#44D2F1']}/>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"chart-wrapper chart-65"}>
                            <BarChart
                                legendPosition={"bottom"}
                                legendHorizontalAlign={"center"}
                                chartTitle={"# de professores e pessoal não docente por escola"}
                                data={[{
                                    name: 'Professores',
                                    data: [3.2, 1.98, 2.6, 1.4]
                                },
                                    {
                                        name: 'Pessoal não docente',
                                        data: [1.2, 3.38, 2.3, 2.0]
                                    },
                                ]}
                                labels={['Escola Básica de 1º CEB /JI da Maia', 'Escola EB1 Florbela Espanca', ' Escola EB1 JI da Barranha', 'Escola do Corpo Santo']}
                                width={"100%"} labelColors={['#F45252', '#4879F5']}/>
                        </div>
                        <div className={"chart-wrapper chart-35"}>
                            <PieChart
                                type={"donut"}
                                legendPosition={"left"}
                                data={[234.00, 987.50]}
                                chartTitle={"Número de bicicletas por agregado"}
                                labels={['Category 1', 'Category 2']}
                                width={"100%"} labelColors={['#F7BA1E', '#14C9C9']}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"chart-wrapper chart-50"}>
                            <PieChart
                                type={"donut"}
                                legendPosition={"left"}
                                data={[234.00, 987.50]}
                                chartTitle={"Local de emprego dos EE"}
                                labels={['Category 1', 'Category 2']}
                                width={"100%"} labelColors={['#F7BA1E', '#14C9C9']}/>
                        </div>

                        <div className={"chart-wrapper chart-50"}>
                            <PieChart
                                type={"donut"}
                                legendPosition={"left"}
                                data={[234.00, 987.50]}
                                chartTitle={"Custos com o transporte escolar no ano letivo 2021/2022"}
                                labels={['Pago pelo município', 'Não suportado']}
                                width={"100%"} labelColors={['#F7BA1E', '#14C9C9']}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"chart-wrapper chart-1"}>
                            <BarChart
                                chartTitle={"% de alunos que utilizam o transporte público em cada escola"}
                                data={[{
                                    name: 'Escola Básica 1º CEB...',
                                    data: [3.2, 1.98, 2.76, 3.5]
                                },
                                    {
                                        name: 'EB1 Florbela Espancao',
                                        data: [1.2, 3.38, 4.06, 2.5]
                                    },
                                    {
                                        name: 'EB1 - JI da Barranha',
                                        data: [3.2, 2.38, 1.06, 3.3]
                                    },
                                    {
                                        name: 'Escola do Corpo Santo',
                                        data: [2.7, 3.6, 3.6, 1.2]
                                    }
                                ]}
                                labels={['1º ciclo Ensino Básico', '2º ciclo Ensino Básico', '3º ciclo Ensino Básico', 'Ensino Secundário Profissional']}
                                width={"100%"} labelColors={['#6C5DD3', '#DD7E55', '#20C745', '#44D2F1']}/>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"chart-wrapper chart-65"}>
                            <BarChart
                                legendPosition={"bottom"}
                                legendHorizontalAlign={"center"}
                                chartTitle={"Evolução dos custos com o transporte escolar e número de alunos"}
                                data={[{
                                    name: 'Professores',
                                    data: [3.2, 1.98, 2.6, 1.4]
                                },
                                    {
                                        name: 'Pessoal não docente',
                                        data: [1.2, 3.38, 2.3, 2.0]
                                    },
                                ]}
                                labels={['Escola Básica de 1º CEB /JI da Maia', 'Escola EB1 Florbela Espanca', ' Escola EB1 JI da Barranha', 'Escola do Corpo Santo']}
                                width={"100%"} labelColors={['#F45252', '#4879F5']}/>
                        </div>
                        <div className={"chart-wrapper chart-35"}>
                            <PieChart
                                type={"donut"}
                                legendPosition={"left"}
                                data={[234.00, 987.50]}
                                chartTitle={"Repartição modal na freguesia em que se localizam as escolas inseridas"}
                                labels={['Category 1', 'Category 2']}
                                width={"100%"} labelColors={['#F7BA1E', '#14C9C9']}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"chart-wrapper chart-50"}>
                            <PieChart
                                type={"donut"}
                                legendPosition={"left"}
                                data={[234.00, 987.50]}
                                chartTitle={"Dimensão dos agregados em que residem os alunos"}
                                labels={['Pago pelo município', 'Não suportado']}
                                width={"100%"} labelColors={['#F7BA1E', '#14C9C9']}/>
                        </div>

                        <div className={"chart-wrapper chart-50"}>
                            <PieChart
                                type={"donut"}
                                legendPosition={"left"}
                                data={[234.00, 987.50]}
                                chartTitle={"Agregados em função do número de crianças"}
                                labels={['Pago pelo município', 'Não suportado']}
                                width={"100%"} labelColors={['#F7BA1E', '#14C9C9']}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Diagnostico;
